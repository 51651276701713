import dayjs from 'dayjs'
class UserFullInfo {
  constructor(data) {
    const mergedData = {
      ...data, // Spread properties from data.data
      ...data.additional_data, // Spread properties from data.data.additional_data
    }
    this.id = mergedData.id
    this.name = mergedData.name
    this.type = mergedData.type
    this.address = mergedData.address
    this.city = mergedData.city
    this.created_at = mergedData.created_at
    this.total_net_worth = mergedData.total_net_worth
    this.step = mergedData.step
    this.avatar = mergedData.avatar
    this.onboarding = mergedData.onboarding
    this.email = mergedData.email
    this.is_activated = mergedData.is_activated
    this.can_delete = mergedData.can_delete
    this.can_restore = mergedData.can_restore
    this.can_convert = mergedData.can_convert
    this.owner_id = mergedData.owner_id
    this.state = mergedData.state
    this.client_id = mergedData.client_id
  }

  get createdAtFormatted() {
    return dayjs(this.created_at).format('MM/DD/YYYY')
  }
}

export { UserFullInfo }
